.work-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.work-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
  margin: 0em 0em 0em 4em;
}

.col-1-4 {
  grid-column: span 1;
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  margin: 3em 1em 0em 1em;
}

.col-2-4 {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  max-height: calc(
    100vh - 100px
  ); /* Adjust height to fit available space, excluding header */
  overflow: auto;
  margin-left: 4em;
  animation: fadeIn 3s;
}

/* Hide scrollbar */
.col-2-4::-webkit-scrollbar {
  width: 0; /* Remove scrollbar width */
}

/* Create space for hidden scrollbar */
.col-2-4 {
  margin-right: -17px; /* Adjust based on the width of the scrollbar */
}

.work-tagline {
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  font-style: normal;
  font-size: 30px;
  display: flex;
  align-self: flex-start;
  padding: 0em 6em 0em 6em;
  animation: slide-bottom 1s;
}

.link-title {
  font-family: 'TAN-BUSTER';
  font-size: 35px;
  color: #ffcae0;
}

.link-title:hover {
  color: #f8f8ff;
  cursor: pointer;
  font-style: italic;
}

.custom-link {
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  font-style: normal;
  font-size: 20px;
  padding-bottom: 2em;
  list-style-type: disc;
  text-decoration: none;
  color: #f8f8ff !important;
}

.custom-link:hover {
  font-style: italic;
  cursor: pointer;
}

.link-desc {
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  font-style: normal;
  font-size: 20px;
}

.col-2-4 p::before {
  display: inline-block;
  width: 1em; /* space between bullet point and text */
  margin-right: 0.5em; /* space between bullet point and text */
}

.col-3-4 {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-bottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Tablet Styles */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .work-grid-container {
    display: grid; /* Retain the desktop grid layout */
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem; /* Reduce gap for better fit */
    margin: 0 auto; /* Center the grid container and remove excess margin */
    padding: 0 2rem;
  }

  .col-1-4 {
    grid-column: span 1; /* Keep the column layout */
    position: sticky;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    margin: 2em 1em 0em 2em; /* Adjust margins */
  }

  .col-2-4 {
    grid-column: span 2; /* Keep the two-column span */
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 120px); /* Slightly reduce height for better fit */
    overflow: auto;
    margin-left: 2em;
  }

  .work-tagline {
    font-size: 24px;
    padding: 0em 4em;
  }

  .link-title {
    font-size: 28px;
  }

  .link-desc {
    font-size: 18px;
  }

  .col-3-4 {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0.5rem;
  }

  h1.mobile-head {
    line-height: 70px;
    margin-bottom: 0.5em;
  }

  .custom-link {
    font-size: 18px;
    padding-bottom: 1.5em; /* Reduce spacing between links */
  }

  .col-2-4::-webkit-scrollbar {
    width: 6px; /* Add a slim scrollbar for better usability on tablets */
  }

  .col-2-4::-webkit-scrollbar-thumb {
    background: rgba(255, 202, 224, 0.6); /* Subtle scrollbar thumb */
    border-radius: 4px;
  }
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .work-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    min-height: 0;
  }

  .work-mobile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* padding-right: 3em; */
  }

  .col-1-4-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 5.2em 1.5em 4em 1.5em;
  }

  h1 {
    font-size: 70px;
    line-height: 100px;
    padding-bottom: 10px;
  }

  .work-tagline-mobile {
    display: flex;
    align-self: center;
    font-family: 'Lato', sans-serif;
    font-weight: 100;
    font-style: normal;
    font-size: 28px;
    margin: 0.5em 0.5em 0em 1em;
    animation: slide-bottom 1s;
  }

  .col-2-4-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0em 0em 2em 0em;
  }

  .custom-link {
    animation: fadeIn 3s;
  }

  .link-title {
    font-size: 20px;
    max-width: 300px; /* Set a max width */
    word-wrap: break-word; /* Ensure text wraps to the next line if too long */
    white-space: normal; /* Allow the text to break into multiple lines */
  }
}
