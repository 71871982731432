.project-container {
  padding: 0em 4em 6em 4em;
}

.project-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.p-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0em 0em 0em 1em;
}

.p-title {
  font-family: 'TAN-BUSTER', 'Lato', sans-serif;
  font-weight: 700;
  font-size: 100px;
  line-height: 120px;
  /* animation: slide-bottom 1s; */
}

.p-subtitle {
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  font-size: 30px;
  animation: slide-bottom 1s;
}

.p-img-container {
  position: relative;
  margin-bottom: 2em;
}

.p-img-container img {
  display: block;
}

.p-image {
  width: 800px;
}

.p-desc {
  /* padding-top: 2em; */
  padding: 1em 0em 0em 0em;
  width: 800px;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  font-size: 30px;
}

.phone-mockup {
  width: 600px;
}

.flyer-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 0em 1em 0em;
  width: 1000px;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  font-size: 30px;
}

.p-tools-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  width: 40%;
}

.p-tools-container h3 {
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  font-size: 30px;
}

.p-tools {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap; /* Ensures items wrap to the next line if needed */
  gap: 1rem; /* Adds spacing between items */
  padding: 1em 0; /* Optional padding for spacing */
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  color: #ffcae0;
}

.p-tools div {
  padding: 0.5em 1em; /* Optional padding around each tool */
  border: 1px solid #ffcae0; /* Optional border */
  border-radius: 4px; /* Optional rounded corners */
}

.p-link {
  padding-top: 3em;
}

.link-btn {
  background-color: #ffcae0;
  color: #362624;
  cursor: pointer;
  transition: background-color 0.3s;
  border: none;
  font-family: 'TAN-BUSTER', 'Lato', sans-serif;
  padding: 1em 5em 1em 5em;
  border-radius: 50px;
  font-size: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.link-btn:hover {
  background-color: #362624;
  /* background-color: #4b3531; */
  border: 1px solid #2c1f1d;
  color: #ffcae0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .project-container {
    margin: 0em 0em 0em 1em;
  }

  .p-content-container {
    margin: 0em 0em 0em 0em;
  }

  .p-title {
    font-size: 40px;
    line-height: 60px;
  }

  .p-subtitle {
    font-family: 'Lato', sans-serif;
    font-weight: 100;
    font-size: 23px;
  }

  .p-image {
    height: 100%;
    width: 290px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .p-desc {
    padding: 0;
    width: 290px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 100;
    font-size: 20px;
  }

  .phone-mockup {
    width: 300px;
  }

  .p-tools-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
    width: 100%;
  }

  .link-btn {
    padding: 0.5em 3em 0.5em 3em;
    border-radius: 50px;
    font-size: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
}
