.grid-container-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0em 0em 0em 4em;
  gap: 5em;
}

.col-1-2-wrapper {
  margin: 0.5em 1em 0em 2em;
}

.col-1-2 {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.col-2-2 {
  grid-column: span 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
}

.contact-head {
  /* line-height: 230px; */
  font-size: 155px;
}

.contact-subhead {
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  font-style: normal;
  font-size: 37px;
  padding-bottom: 1em;
  animation: slide-bottom 1s;
}

.contact-form {
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 10px;
}

.contact-form label {
  text-align: left;
  margin-right: 10px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}

.contact-form input {
  width: 345px;
  height: 25px;
  padding: 5px;
  border-radius: 5px;
  background-color: #f8f8ff !important;
}

.contact-form textarea {
  width: 345px;
  height: 150px;
  margin-bottom: 12px;
  border-radius: 5px;
  background-color: #f8f8ff;
  padding: 5px;
  vertical-align: top; /* text alignment to top */
  font-family: 'Lato', sans-serif;
}

.submit-btn {
  background-color: #ffcae0;
  color: #362624;
  cursor: pointer;
  transition: background-color 0.3s;
  border: none;
  font-family: 'TAN-BUSTER', 'Lato', sans-serif;
  padding: 7px;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.contact-form button:hover {
  background-color: #382725;
  border: 1px solid #2c1f1d;
  color: #ffcae0;
}

.thank-you-message {
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-style: italic;
  padding-top: 7px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.1s ease, transform 0.1s ease;
}

.thank-you-message.show {
  opacity: 1;
  transform: translateY(0);
}

@keyframes slide-bottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Tablet styles */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    gap: 2em;
  }

  .grid-container-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    gap: 1.5em;
  }

  .col-1-2-wrapper {
    margin: 0; /* Reset margin for stacking */
    width: 100%; /* Take full width */
  }

  .col-1-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 2em; /* Add spacing between sections */
  }

  .col-2-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; /* Take full width */
  }

  .contact-form {
    width: 90%; /* Make the form responsive */
    margin: 0 auto; /* Center the form */
  }

  .contact-form input,
  .contact-form textarea {
    width: 100%; /* Full width for form fields */
    max-width: 500px; /* Optional: Set a max width for large screens */
  }

  .contact-form textarea {
    height: 150px;
  }

  .submit-btn {
    width: 200px;
    margin: 1em auto; /* Add spacing and center */
  }

  .contact-head {
    font-size: 72px;
    line-height: 80px;
  }

  .contact-subhead {
    font-size: 30px;
  }
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .contact-container-mobile {
    margin: 5.2em 0em 0em 1em;
  }

  .contact-head {
    font-size: 52px;
    line-height: 60px;
  }

  .contact-subhead {
    font-size: 25px;
    padding: 0em 0.5em 1em 0.5em;
  }

  .col-2-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact-form {
    margin: 0em 2em 5em 2em;
  }

  .contact-form input {
    width: 275px;
    height: 25px;
    padding: 5px;
    border-radius: 5px;
    background-color: #f8f8ff;
  }

  .contact-form textarea {
    width: 275px;
    height: 150px;
    margin-bottom: 12px;
    border-radius: 5px;
    background-color: #f8f8ff;
    padding: 5px;
    vertical-align: top; /* text alignment to top */
    font-family: 'Lato', sans-serif;
  }

  .submit-btn {
    padding: 10px 0px 10px 0px;
  }
}
