.about-container {
  display: flex;
  flex-direction: column;
  padding: 0em 4em 0em 4em;
  margin-top: 9em;
  font-family: 'Lato', sans-serif;
}

.grid-container-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
  padding: 0em 4em 0em 4em;
  margin-bottom: 5em;
}

.col-1-3 {
  grid-column: span 2;
  margin-right: 4em;
}

.col-2-3 {
  grid-column: span 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.about-head {
  font-size: 175px;
}

.about-tagline {
  font-weight: 100;
  font-style: normal;
  font-size: 37px;
  padding-bottom: 1em;
  animation: fadeIn 1s;
}

.about-text {
  font-weight: 100;
  font-style: normal;
  font-size: 23px;
  color: #f8f8ff;
  animation: fadeIn 1s;
}

.about-img {
  animation: slide-bottom 1s;
}

.about-header {
  font-family: 'TAN-BUSTER';
  font-size: 50px;
  color: #ffcae0;
}

.experience {
  width: 70%;
  padding: 0em 4em 4em 4em;
}

.experience ul {
  font-weight: 100;
  font-style: normal;
  font-size: 23px;
}

#exp-list li {
  padding-left: 1em;
}

.skills {
  width: 70%;
  padding: 0em 4em 2em 4em;
}

.skills li {
  list-style: none;
  font-weight: 100;
  font-style: normal;
  font-size: 23px;
  margin-right: 10em;
}

.sub-cat {
  font-weight: 300;
  font-style: normal;
  font-size: 28px;
  padding-bottom: 0.5em;
}

.dev-cat {
  font-weight: 300;
  font-style: normal;
  font-size: 28px;
  padding: 1em 0em 0.5em 0em;
}

.btn-container {
  display: flex;
  align-items: center;
  padding: 0em 4em 4em 4em;
}

.resume-btn {
  background-color: #ffcae0;
  color: #362624;
  cursor: pointer;
  transition: background-color 0.3s;
  border: none;
  font-family: 'TAN-BUSTER', 'Lato', sans-serif;
  padding: 1em 5em 1em 5em;
  border-radius: 50px;
  font-size: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.resume-btn:hover {
  background-color: #382725;
  border: 1px solid #2c1f1d;
  color: #ffcae0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-bottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


/* Tablet Styles */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .about-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align text and content to the left */
    padding: 0 4em; /* Match padding for consistency */
    margin-top: 9em;
    font-family: 'Lato', sans-serif;
    max-width: 100%; /* Make content stretch across the screen */
    margin-left: auto;
    margin-right: auto;
  }

  .grid-container-3 {
    display: flex; /* Switch from grid to flex */
    flex-direction: column; /* Stack items vertically */
    gap: 2em; 
    padding: 0;
    margin: 0;
  }

  .col-1-3 {
    width: 100%; 
    text-align: left; 
    padding: 0em 4em 4em 4em; 
    margin-top: 3em; 
  }

  .about-head {
    font-size: 150px; 
    text-align: left; 
    width: 100%; /* Ensure it stretches full width */
  }

  .about-tagline {
    font-size: 30px; 
    font-weight: 100;
    text-align: left; 
    width: 100%; /* Stretch text to full width */
  }

  .about-text {
    font-size: 20px; 
    font-weight: 100;
    text-align: left; 
    max-width: 800px; /* Restrict maximum width to prevent overflow */
    line-height: 1.6; /* Improve readability */
    overflow-wrap: break-word;
  }

  .experience {
    width: 100%; /* Make it full width */
    padding: 0em 4em 4em 4em; /* Match padding with col-1-3 */
    margin-top: 1em; 
  }

  .experience ul {
    font-size: 20px; 
    padding-left: 1em;
  }

  .skills {
    width: 100%; 
    padding: 0em 4em 1em 4em; 
    margin-top: 1em; 
  }

  .skills ul {
    font-size: 20px; 
    padding-left: 1em; 
  }

  .btn-container {
    display: flex;
    justify-content: flex-start; 
    width: 100%; 
    padding: 0em 4em 4em 4em; 
  }

  .resume-btn {
    padding: 1em 4em;
    font-size: 18px;
  }
}



/* Mobile styles */
@media screen and (max-width: 768px) {
  .about-container {
    margin: 0;
  }

  .about-main-container-mobile {
    margin: 0;
  }

  .about-header-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5.2em 0em 0em 0em;
  }

  .about-header {
    font-size: 65px;
  }

  .about-header-content-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 2em;
  }

  .about-content-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 4em;
  }

  .about-tagline {
    font-size: 28px;
  }

  .about-text {
    font-size: 21px;
  }

  .about-subheader-mobile {
    font-size: 35px;
    font-family: 'TAN-BUSTER';
    color: #ffcae0;
    padding-bottom: 10px;
  }

  .experience-mobile {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3em;
    text-align: center;
  }

  #exp-list-mobile {
    text-align: left;
    list-style-position: inside;
  }

  #exp-list-mobile li {
    font-family: 'Lato', sans-serif;
    font-weight: 100;
    font-style: normal;
    font-size: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
  }

  .skills-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .skill-list-mobile {
    text-align: center;
  }

  .skill-list-mobile li {
    font-family: 'Lato', sans-serif;
    font-weight: 100;
    font-style: normal;
    font-size: 20px;
    margin-left: 0em 1.5em 0em 0.5em;
    list-style: none;
  }

  span {
    font-weight: 100;
    padding: 0px 6px 0px 6px;
  }

  .sub-cat {
    font-weight: 300;
    padding-bottom: 10px;
  }

  .dev-cat {
    font-weight: 300;
    padding-bottom: 10px;
  }

  .btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .resume-btn {
    padding: 1em 5em 1em 5em;
  }
}
