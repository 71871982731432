@font-face {
  font-family: 'TAN-BUSTER';
  src: url(./font/TAN-BUSTER.ttf) format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #2c1f1d;
}

* {
  margin: 0;
  padding: 0;
}

.App {
  height: 100vh;
  color: #f8f8ff;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 4rem 2rem;
  transform: rotate(-90deg);
}

.copyright {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 15px;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 3em;
}

.error-header {
  color: #ffcae0;
  font-family: 'TAN-BUSTER';
  font-size: 140px;
  align-items: center;
  line-height: 230px;
}

.error-tagline {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 30px;
}

.btn-container {
  display: flex;
  align-items: center;
  margin-top: 3em;
}

.back-btn {
  background-color: #ffcae0;
  color: #362624;
  cursor: pointer;
  transition: background-color 0.3s;
  border: none;
  font-family: 'TAN-BUSTER', 'Lato', sans-serif;
  padding: 1em 2em 1em 2em;
  border-radius: 50px;
  font-size: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.back-btn:hover {
  /* background-color: #382725; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #2c1f1d;
  color: #ffcae0;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .main-container {
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .home-link {
    position: fixed;
    top: 0;
    left: 1;
    padding: 4rem 2rem;
    transform: rotate(-90deg);
    margin: 0rem 0rem 2rem 1rem;
    padding: 0;
  }

  .social-nav {
    position: fixed;
    top: 0;
    left: 0;
    transform: rotate(-90deg);
  }

  .social-nav li:active {
    color: #ffcae0;
    cursor: pointer;
    font-style: italic;
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    transform: rotate(-90deg);
    margin: 0rem 0rem 2rem 0rem;
    padding: 0;
  }

  .error-container {
    margin: 0px 16px 0px 16px;
  }

  .error-header {
    font-size: 50px;
    line-height: 100px;
  }

  .error-tagline {
    font-size: 25px;
    font-weight: 100;
  }
}
